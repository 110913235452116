<template>
  <div class="fs-container">
      <div class="fs-section fs-article">
          <div class="banner-box">
              <div class="fs-pic banner-pic">
                  <img class="cmPic" :src="HotDealDetail.BannerImage"
                      :alt="HotDealDetail.Title">
              </div>
              <div class="banner-info">
                  <h1 v-html="HotDealDetail.Title"></h1>
                  <p><strong><span class="color-red">{{HotDealDetail.Tagging}}</span> NEWS
                          {{HotDealDetail.month_}}</strong>
                      {{HotDealDetail.days}}
                      DAYS AGO</p>
              </div>

          </div>

          <div class="fs-inr article-inr">
              <div class="acticle-detail">

                  <div class="acticle-top">
                      <div class="user-box">
                          <div class="user-pic"><img :src="HotDealDetail.AuthorIcon"
                                  :alt="HotDealDetail.Author">
                          </div>
                          <div class="user-txt">{{HotDealDetail.Author}}</div>
                      </div>
                      <div class="social">
                          <div class="social-inr">
                              <div class="fb-like" :data-href="currentUrl" data-width="" data-layout="button"
                                  data-action="like" data-size="large" data-share="true"></div>
                          </div>
                          <div class="tag">TAG</div>
                      </div>
                  </div>
                  <div class="article-content">
                      <img v-if="HotDealDetail.DetailImage!=null"
                          :src="HotDealDetail.DetailImage" :alt="HotDealDetail.Title" />
                      <div class="detail-wrap" v-html="HotDealDetail.Detail"></div>
                  </div>
                  <div class="social-box">
                      <ul>
                          <li class="icon-like"><img src="@/assets/images/like-icon.png" alt="Like"></li>
                          <li class="icon-facebook"><img src="@/assets/images/facebook-icon.png" alt="facebook"></li>
                      </ul>
                  </div>

                  <div class="news-relation" v-if="ListRelatedHotDeal.length>0">
                      <h2>{{ $t('Common.Next') }}</h2>
                      <div class="relation-items">
                          <router-link class="relation-item" v-for="hotDeal of ListRelatedHotDeal" :key="hotDeal.Slug"
                              :to="$t('Route.HotDeal')+'/'+hotDeal.Slug">
                              <div class="fs-pic"><img :src="hotDeal.Thumbnail" :alt="hotDeal.Title">
                              </div>
                              <div class="fs-txt">
                                  <span class="brand">{{hotDeal.Tagging}}</span>
                                  <h3 v-html="hotDeal.Title"></h3>
                              </div>
                          </router-link>
                      </div>
                  </div>

              </div>
          </div>

      </div>

  </div>

</template>

<script>
import { Navigation, Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";
import { isMobile } from 'mobile-device-detect';

// Import Swiper styles
import 'swiper/swiper-bundle.css'
SwiperCore.use([Navigation, Pagination])
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images['assets/images/ve-itel/'+item.replace('./', '')] = r(item);    
  });
  return images;
}
const images = importAll(require.context('@/assets/images/ve-itel/', false, /.(png)$/));


export default {
  components: {
    Swiper, SwiperSlide
  },
  data() {
    return {
      HotDealDetail:{},
      ListRelatedHotDeal:[],
      ListRandomHotDeal:[],
      videoId:'',
      modalClass:'',
      Slug: '',
      currentUrl: "",
    }
  },
  watch: {
    $route(to, from) {
      this.fetch();
    }
  },
  created() {
    this.currentUrl = window.location.href;
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  mounted(){
    this.setAnimation();
    this.fetch();
    var t_ = this;
    EventBus.$on('CHANGE_LANG', function (payLoad) {
      t_.fetch();
    });
  },
  methods: {
    Img(pic){
      return images[pic];
    },
    fetch(){
      this.loading(true);
      useJwt.post('getMasterData/GetHotDealDetails',{columnFilters:{Slug:this.$route.params.Id},sort:[],page:1,pageSize:1000,lang:this.$l}).then(response =>{
        this.HotDealDetail = response.data.result.HotDealDetail[0];
        this.ListRelatedHotDeal = response.data.result.ListRelatedHotDeal;
        this.ListRandomHotDeal = response.data.result.ListRandomHotDeal;
        this.loading(false);
      }).catch(err=>{
        this.loading(false);
        this.$toast.error('Có lỗi trong khi lấy dữ liệu trang, Quý khách vui lòng thử lại!', {icon:true, closeButton: "button"})
      })
    },
    openVideo(url){
      this.modalClass = 'modal__open';
      this.videoId = 'https://www.youtube.com/embed/' + url + '?rel=0&amp;autoplay=1&amp;playsinline=1';
    },
    closeVideo(){
      this.videoId='';
      this.modalClass = '';
    },
    setAnimation() {
      const windowH = window.screen.height;
      const headH = document.querySelector('.fs-header').clientHeight;
      [].slice.call(document.querySelectorAll('.fs-banner, .choose-pic, .choose-txt, .pack-txt, .pack-info, .i-news-infos, .video-box, .support-pic, .support-txt, .like-pic, .welcome-inr .fs-txt, .welcome-inr .fs-pic, .why-inr .fs-pic, .item-reseaon, .hand-pic, .hand-txt,.about-item, .block-title, .mission-item, .value-item, .promotion-video-box, .vision-inr, .fs-distributions .fs-inr')).forEach((elm) => {
        const style = window.getComputedStyle(elm, null);
        const elmH = style.getPropertyValue('height');
        const inputT = windowH - elm.getBoundingClientRect().top;
        if (inputT > 0 && inputT < (windowH + parseInt(elmH, 10) - headH / 2)) {
          elm.classList.add('fs-ani');
        } else {
          elm.classList.remove('fs-ani');
        }
      });
    }
  }
}
</script>
